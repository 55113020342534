<template>
  <modal name="modal-confirmacao-sair" scrollable width="400" height="auto">
    <div style="padding: 32px">
      <h2 class="text-center">⚠️ Alterações não salvas</h2>
      <div class="warning-messages-container">
        <div class="d-block" style="margin-top: 32px">
          * Tem certeza que deseja sair e descartar as alterações?
        </div>
      </div>
      <div
        class="buttons-container"
        style="
          display: flex;
          gap: 1rem;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
        "
      >
        <b-button
          class="w-100 text-uppercase"
          variant="success"
          @click="redirectToCampanhas"
          >Sim</b-button
        >
        <b-button
          class="w-100 text-uppercase"
          variant="danger"
          @click="fecharModalSaida"
          >Não</b-button
        >
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ModalConfirmacaoSair",
  props: {
    aba: {
      type: Number,
      default: 0
    },
    modalRegister: {
      type: Boolean
    },
    direct_page: {
      type: String
    }
  },
  methods: {
    fecharModalSaida() {
      this.$modal.hide("modal-confirmacao-sair");
    },
    redirectToCampanhas() {
      if (this.direct_page) {
        this.$router.push({
          name: this.direct_page
        });
        return;
      }
      if (this.modalRegister) {
        this.$modal.hide("modal-confirmacao-sair");
      }
      this.$router.push({
        name: "automacao",
        params: {
          navSelecionada: this.aba
        }
      });
    }
  }
};
</script>

<style></style>
