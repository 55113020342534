<template>
  <div class="row">
    <div class="col-3 align-items-center">
      <div class="d-flex">
        <p class="text-title">Licenciada</p>
      </div>
      <div class="d-flex align-items-center">
        <b-form-group class="w-100">
          <b-form-select
            class="w-100"
            name="licenciada"
            :options="licenciadaOpcoes"
            @change="pegarLicenciadasDoBanco"
            v-model="sistemaLicenciadaIdVmodel"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>

    <div class="col-3">
      <div class="d-flex">
        <p class="text-title">Tipo</p>
      </div>

      <b-form-group class="w-100">
        <b-form-select
          class="w-100"
          name="tipo"
          :options="modeloTiposOpcoes"
          @change="modeloPossiveis"
          v-model="modeloTiposVmodel"
        ></b-form-select
      ></b-form-group>
    </div>
    <div class="col-6">
      <div class="d-flex">
        <p class="text-title">Modelo</p>
      </div>
      <b-form-group class="w-100">
        <b-form-select
          name="modelo"
          class="w-100"
          :options="modeloPossiveisOpcoes"
          @change="selecionarModelo(modeloVmodel)"
          v-model="modeloVmodel"
        ></b-form-select
      ></b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  //   props: {
  //     licenciadaOpcoes: Array,
  //     modeloTiposOpcoes: Array,
  //     modeloPossiveisOpcoes: Array,
  //     sistemaLicenciadaIdVmodel: Any,
  //     modeloTiposVmodel: Any,
  //     modeloVmodel: Any
  //   },
  props: [
    "licenciadaOpcoes",
    "modeloTiposOpcoes",
    "modeloPossiveisOpcoes",
    "sistemaLicenciadaId",
    "modeloTipos",
    "modelo"
  ],
  data() {
    return {
      sistemaLicenciadaIdVmodel: null,
      modeloTiposVmodel: null,
      modeloVmodel: null,
      alreadyMounted: false
    };
  },
  methods: {
    pegarLicenciadasDoBanco() {
      this.$emit("pegarLicenciadasDoBanco", this.sistemaLicenciadaIdVmodel);
    },
    modeloPossiveis() {
      this.$emit("modeloPossiveis", this.modeloTiposVmodel);
    },
    selecionarModelo() {
      this.$emit("selecionarModelo", this.modeloVmodel);
    }
  },
  mounted() {
    this.sistemaLicenciadaIdVmodel = this.sistemaLicenciadaId;
    this.modeloTiposVmodel = this.modeloTipos;
    this.modeloVmodel = this.modelo;
  },
  watch: {
    sistemaLicenciadaId: {
      handler(val) {
        this.sistemaLicenciadaIdVmodel = val;
      },
      deep: true,
      imediate: true
    },
    modeloTipos: {
      handler(val) {
        this.modeloTiposVmodel = val;
      },
      deep: true,
      imediate: true
    },
    modelo: {
      handler(val) {
        this.modeloVmodel = val;
      },
      deep: true,
      imediate: true
    }
  }
};
</script>

<style></style>
